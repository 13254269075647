import {
  signIn,
  type SignInInput,
  signUp,
  ConfirmSignUpInput,
  confirmSignUp,
  resendSignUpCode,
  ResendSignUpCodeInput,
} from 'aws-amplify/auth';
import { useCallback, useState } from 'react';

type SignUpParameters = {
  username: string;
  password: string;
  email: string;
  phone_number: string;
};

export const useAuth = () => {
  const [isLoading, setIsLoading] = useState(false);

  const handleSignIn = useCallback(
    async ({ username, password }: SignInInput) => {
      try {
        setIsLoading(true);
        const response = await signIn({ username, password });

        return response;
      } finally {
        setIsLoading(false);
      }
    },
    [],
  );

  const handleSignUp = useCallback(
    async ({ password, email }: SignUpParameters) => {
      try {
        setIsLoading(true);
        const response = await signUp({
          username: email,
          password,

          options: {
            userAttributes: {
              email,
              'custom:role': 'manager',
            },
            autoSignIn: true,
          },
        });

        return response;
      } finally {
        setIsLoading(false);
      }
    },
    [],
  );

  const handleOAuth = async () => {};

  const handleSignUpConfirmation = async ({
    username,
    confirmationCode,
  }: ConfirmSignUpInput) => {
    try {
      setIsLoading(true);
      const response = await confirmSignUp({
        username,
        confirmationCode,
      });

      return response;
    } finally {
      setIsLoading(false);
    }
  };

  const resendCode = async ({ username }: ResendSignUpCodeInput) => {
    try {
      await resendSignUpCode({ username });
    } catch {}
  };

  return {
    handleSignIn,
    handleSignUp,
    handleOAuth,
    handleSignUpConfirmation,
    resendCode,
    isLoading,
  };
};
