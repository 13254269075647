import React, { createContext, FC, PropsWithChildren, useContext } from 'react';
import { AuthUser, FetchUserAttributesOutput } from 'aws-amplify/auth';

import { BusinessFragment } from '@app/api/schemas/business.mongo.generated';
import { AccountFragment } from '@app/api/schemas/account.mongo.generated';

interface AccountContextProps {
  account: AccountFragment;
  user: AuthUser;
  business: BusinessFragment;
  userAttributes: FetchUserAttributesOutput;
}

interface CurrentAccountProviderProps extends PropsWithChildren {
  account: AccountFragment;
  user: AuthUser;
  userAttributes: FetchUserAttributesOutput;
}

export const CurrentAccountContext = createContext<AccountContextProps>(null);

export const useCurrentAccountContext = () => {
  const context = useContext(CurrentAccountContext);

  if (!context) {
    throw new Error(
      'useCurrentAccountContext must be used within a CurrentAccountProvider',
    );
  }

  return context;
};

const CurrentAccountProvider: FC<CurrentAccountProviderProps> = ({
  account,
  user,
  userAttributes,
  children,
}) => {
  return (
    <CurrentAccountContext.Provider
      value={{
        account,
        user,
        userAttributes,
        business: account?.business,
      }}>
      {children}
    </CurrentAccountContext.Provider>
  );
};

export default CurrentAccountProvider;
