import * as Types from '@/gql/__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CurrencyFragment = { __typename?: 'Currency', _id: string, code: string | null, name: string | null, symbol: string | null };

export type CurrenciesQueryVariables = Types.Exact<{
  filter: Types.InputMaybe<Types.FilterInput>;
  pagination: Types.InputMaybe<Types.PaginationInput>;
}>;


export type CurrenciesQuery = { __typename?: 'Query', currencies: { __typename?: 'CurrencyList', hasNext: boolean, nextCreatedAt: string | null, nextId: string | null, total: number, items: Array<{ __typename?: 'Currency', _id: string, code: string | null, name: string | null, symbol: string | null }> } };

export const CurrencyFragmentDoc = gql`
    fragment Currency on Currency {
  _id
  code
  name
  symbol
}
    `;
export const CurrenciesDocument = gql`
    query Currencies($filter: FilterInput, $pagination: PaginationInput) {
  currencies(filter: $filter, pagination: $pagination) {
    hasNext
    items {
      ...Currency
    }
    nextCreatedAt
    nextId
    total
  }
}
    ${CurrencyFragmentDoc}`;

/**
 * __useCurrenciesQuery__
 *
 * To run a query within a React component, call `useCurrenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrenciesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useCurrenciesQuery(baseOptions?: Apollo.QueryHookOptions<CurrenciesQuery, CurrenciesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrenciesQuery, CurrenciesQueryVariables>(CurrenciesDocument, options);
      }
export function useCurrenciesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrenciesQuery, CurrenciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrenciesQuery, CurrenciesQueryVariables>(CurrenciesDocument, options);
        }
export function useCurrenciesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CurrenciesQuery, CurrenciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CurrenciesQuery, CurrenciesQueryVariables>(CurrenciesDocument, options);
        }
export type CurrenciesQueryHookResult = ReturnType<typeof useCurrenciesQuery>;
export type CurrenciesLazyQueryHookResult = ReturnType<typeof useCurrenciesLazyQuery>;
export type CurrenciesSuspenseQueryHookResult = ReturnType<typeof useCurrenciesSuspenseQuery>;
export type CurrenciesQueryResult = Apollo.QueryResult<CurrenciesQuery, CurrenciesQueryVariables>;