import {
  Avatar,
  AvatarBadge,
  Box,
  Flex,
  HStack,
  Image,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { MessageAttachmentType } from '@app/api/gql/generated-types';
import { ChatPreviewFragment } from '@app/api/schemas/chat.mongo.generated';

import { LastMessageReference } from './LastMessageReference';

interface ChatHeaderProps {
  item: ChatPreviewFragment;
  isActive: boolean;
  isWidget?: boolean;
}

const imageServer = process.env.REACT_APP_AMAZON_IMAGE_SERVER;

export const ChatHeader: FC<ChatHeaderProps> = ({
  item,
  isActive = false,
  isWidget = false,
}) => {
  const { t } = useTranslation(['profile']);
  const { id } = useParams();
  const textColor = useColorModeValue(
    isActive ? 'white.0' : 'black.100',
    'white',
  );

  const companion = item?.participantsExcludingSelf?.[0];

  const title = useMemo(() => {
    if (item?.title) {
      return item.title;
    }
    if (companion?.fullName) {
      return `${t('chatWith')} ${companion?.fullName}`;
    }

    if (companion?.firstName || companion?.lastName) {
      return `${t('chatWith')} ${companion?.firstName} ${companion?.lastName}`;
    }

    return t('chat');
  }, [
    companion?.firstName,
    companion?.fullName,
    companion?.lastName,
    item.title,
    t,
  ]);

  const name =
    companion?.fullName ?? `${companion?.firstName} ${companion?.lastName}`;
  const avatar = companion?.avatar?.key
    ? `${imageServer}/${companion?.avatar?.key}?width=200&format=webp`
    : '';

  const borderColor = useColorModeValue('secondaryGray.400', 'whiteAlpha.100');

  return (
    <Flex
      align="center"
      backgroundColor={isActive ? 'primary.green' : 'transparent'}
      borderBottom={isWidget ? 'none' : isActive ? 'none' : '1px solid'}
      borderColor={borderColor}
      borderRadius={isWidget ? '50%' : 'none'}
      boxShadow={isActive ? '0px 5px 15px 0px #101B4F26' : 'none'}
      padding={isWidget ? '3px' : { base: 4, md: '12px 16px' }}
      position="relative">
      <Avatar
        h={10}
        me={isWidget ? '0' : '16px'}
        name={name}
        src={avatar}
        w={10}>
        {!!item?.unreadCount && (
          <AvatarBadge
            backgroundColor="red"
            color="white"
            fontSize={12}
            h={5}
            minW={5}
            right={0}
            top={-2}>
            {item.unreadCount}
          </AvatarBadge>
        )}
        {!!companion?.isOnline && (
          <Box
            background="primary.green"
            border="1px solid white"
            borderRadius="50%"
            bottom="-1px"
            h="12px"
            position="absolute"
            right="-1px"
            w="12px"
          />
        )}
      </Avatar>
      {!isWidget && (
        <Flex
          alignItems="flex-start"
          display={{ base: !!id ? 'none' : 'flex', md: 'flex' }}
          flexDirection="row"
          gap="4px">
          <Box>
            <Text
              color={textColor}
              fontSize={{ base: '12px', md: '14px' }}
              fontWeight={!!item?.unreadCount ? 700 : 500}
              mb="2px"
              noOfLines={1}>
              {title}
            </Text>
            <LastMessageReference
              isActive={isActive}
              item={item?.lastMessage?.reference}
            />
            <HStack>
              {item?.lastMessage?.attachments?.[0]?.type ===
                MessageAttachmentType.IMAGE && (
                <Image
                  borderRadius={4}
                  height="20px"
                  objectFit="cover"
                  src={`${imageServer}/${item?.lastMessage?.attachments?.[0]?.url}?width=100&format=webp`}
                  width="20px"
                />
              )}

              <Text
                color={
                  isActive
                    ? 'white.0'
                    : !!item?.unreadCount
                    ? 'navy.700'
                    : 'secondaryGray.500'
                }
                h="22px"
                noOfLines={2}
                overflow="hidden"
                textOverflow="ellipsis"
                w="187px"
                whiteSpace="nowrap">
                {item.lastMessage?.content ?? ' '}
              </Text>
            </HStack>
          </Box>
        </Flex>
      )}
    </Flex>
  );
};
