import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  OfferPrice,
  OfferPriceEstimateType,
} from '@app/api/gql/generated-types';
import { formatCurrency } from '@app/utils/formatCurrency';

interface PriceFieldProps {
  value: OfferPrice;
  type?: OfferPriceEstimateType;
}

export const PriceField: FC<PriceFieldProps> = ({ value, type }) => {
  const { t } = useTranslation('tables');
  if (!value?.value || !value?.currency?.code) {
    return null;
  }
  return (
    <>
      {type === OfferPriceEstimateType.ESTIMATE && `${t('fromPrice')} `}
      {
        formatCurrency({
          amount: value?.value,
          code: value?.currency?.code,
        })?.[0]
      }
    </>
  );
};
