import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';

import { createPaginationVariables } from '@app/api/apollo-pagination';
import { useApolloLoadingStatus } from '@app/hooks/useApolloLoadingStatus';
import { useFindOrdersQuery } from '@app/api/schemas/order.mongo.generated';

import { initialValues } from '../components/Filter/initialValues';

export const useOrdersData = ({
  pollInterval = null,
}: {
  pollInterval?: number;
}) => {
  const form = useForm({
    defaultValues: initialValues,
    mode: 'all',
  });

  const [price, dates, status] = form.watch(['price', 'dates', 'status']);

  const onReset = () => form.reset();

  const filter = {
    ...((!!price?.from || !!price?.to) && {
      price: {
        from: Number(price.from),
        to: Number(price.to),
      },
    }),

    ...(!!status?.length && { status: status?.map((v) => v.value) }),
    ...(!!dates?.from && { startAt: new Date(dates?.from).toISOString() }),
    ...(!!dates?.to && { endAt: new Date(dates?.to).toISOString() }),
  };

  const {
    data: { findOrders } = {},
    networkStatus,
    fetchMore,
    refetch,
  } = useFindOrdersQuery({
    variables: {
      input: {
        limit: 20,
        filter,
      },
    },
    pollInterval,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });
  const orders = useMemo(() => findOrders?.items ?? [], [findOrders?.items]);
  const { isLoading, isLoadingData, isReady } =
    useApolloLoadingStatus(networkStatus);
  const fetchNextPage = async () => {
    try {
      if (findOrders?.hasNextPage && !isLoadingData) {
        await fetchMore(createPaginationVariables(findOrders, 20, filter)); //filter
      }
    } catch (error) {
      console.error(error);
    }
  };

  return {
    orders,
    form,
    onReset,
    fetchNextPage,
    hasNextPage: findOrders?.hasNextPage,
    total: findOrders?.total,
    isLoading,
    isLoadingData,
    isReady,
    refetch,
    statusCounts: findOrders?.statusCounts,
    isFiltered: !isEmpty(filter),
  };
};
