import { Box, useDisclosure } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { Outlet, matchRoutes, useLocation } from 'react-router-dom';

import { GreetingModal } from '@app/components/GreetingModal';
import Sidebar from '@app/components/Sidebar';
import { ReviewModals } from '@app/components/ReviewModals';
import NotificationProvider from '@app/providers/NotificationProvider';
import { SidebarContext } from '@app/contexts/SidebarContext';
import ChatWidgetProvider from '@app/providers/ChatWidgetProvider';
import { ChatWidget } from '@app/widgets/Chats';
import AddOfferModalProvider from '@app/providers/AddOfferModalProvider';
import { useNotificationsQuery } from '@app/api/schemas/notification.mongo.generated';
import { NotificationWidget } from '@app/components/NotificationWidget';

export const isGreetingModalOpenedKey = 'isGreetingModalOpened';

const routes = [{ path: '/chat' }, { path: '/chat/:id' }];

export const MainLayout = () => {
  const location = useLocation();
  const match = matchRoutes(routes, location);

  const {
    onToggle,
    isOpen: toggleSidebar,
    onClose,
    onOpen,
  } = useDisclosure({ defaultIsOpen: true });
  const greetingModalDisclosure = useDisclosure();

  const mainPageWidth = !toggleSidebar ? '74px' : '350px';

  const isChatPage = useMemo(() => !!match?.length, [match?.length]);

  return (
    <Box position="relative">
      <NotificationProvider>
        <SidebarContext.Provider
          value={{
            toggleSidebar,
            onToggleSideBar: onToggle,
            onCloseSidebar: onClose,
            onOpenSidebar: onOpen,
          }}>
          <Sidebar />

          <ChatWidgetProvider>
            <AddOfferModalProvider>
              <Box
                float="right"
                height="100%"
                maxHeight="100%"
                maxWidth={{
                  base: '100%',
                  md: `calc( 100% - ${mainPageWidth} )`,
                }}
                minHeight="100dvh"
                // overflow="auto"
                position="relative"
                transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
                transitionDuration=".2s, .2s, .35s"
                transitionProperty="top, bottom, width"
                transitionTimingFunction="linear, linear, ease"
                w={{ base: '100%', xl: `calc( 100% - ${mainPageWidth} )` }}>
                <Box minH="100dvh" mx="auto" p={{ base: 0, md: '0px 22px' }}>
                  <Outlet />
                </Box>
              </Box>
              {!isChatPage && <ChatWidget />}
            </AddOfferModalProvider>
          </ChatWidgetProvider>
          <GreetingModal {...greetingModalDisclosure} />
          {/* <ReviewModals /> */}
        </SidebarContext.Provider>
      </NotificationProvider>
    </Box>
  );
};
