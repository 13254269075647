import { FindOrdersInputFilter } from '@app/api/gql/generated-types';

export const initialValues = {
  price: {
    from: null,
    to: null,
  },
  dates: {
    from: null,
    to: null,
  },
  status: [],
} as FindOrdersInputFilter;
