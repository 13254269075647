import { Box, Text } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Cell } from 'react-table';
import { addDays } from 'date-fns';

import { DateFormatter } from '@app/components/DateFormatter';
import { PriceField } from '@app/components/PriceField';
import { TextColumnTag } from '@app/views/Requests/components/TextColumnTag';
import { statusOrderColor } from '@app/hooks/useStatusColor';
import { AdultKidsCell } from '@app/components/Tables/AdultKidsCell';
import { OrderPreviewFragment } from '@app/api/schemas/order.mongo.generated';

export const useOrdersColumns = () => {
  const { t } = useTranslation('tables');
  const columns = [
    {
      Header: `📜 ${t('offer')}`,
      accessor: 'photo',
      Cell: ({ row }: Cell<OrderPreviewFragment>) => {
        const endDate = !!row.original?.offer?.startDate
          ? addDays(
              new Date(row.original.offer.startDate),
              Number(row.original.offer?.days),
            )
          : null;

        return (
          <>
            <Box
              backgroundColor={statusOrderColor(row.original?.status)}
              borderRadius="4px"
              height="calc(100% - 2px)"
              left={0}
              position="absolute"
              top="1px"
              w="3px"
            />
            <Box w="320px">
              <Box
                alignItems={'flex-start'}
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                minW="full">
                {!!row.original?.orderNumber && (
                  <TextColumnTag
                    color="mainGray.300"
                    fontSize={14}
                    value={'#' + row.original?.orderNumber}
                  />
                )}
                <TextColumnTag
                  noOfLines={1}
                  value={row.original?.offer?.title}
                />
                <Text
                  color="mainGray.300"
                  fontSize={12}
                  fontWeight={400}
                  lineHeight="16px"
                  noOfLines={1}>
                  <DateFormatter
                    date={new Date(row.original?.offer?.startDate)}
                    format="PP"
                  />{' '}
                  - <DateFormatter date={endDate} format="PP" />
                </Text>
              </Box>
            </Box>
          </>
        );
      },
    },

    {
      Header: `👥 ${t('travelers')}`,
      accessor: 'offer.adults',
      Cell: ({ row }: Cell<OrderPreviewFragment>) => {
        const adults = row.original?.offer?.adults;
        const children = row.original?.offer?.children;
        return <AdultKidsCell children={children} adults={adults} />;
      },
    },

    {
      Header: `💰 ${t('price')}`,
      accessor: 'price',
      Cell: ({ row }: Cell<OrderPreviewFragment>) => {
        return (
          <TextColumnTag
            value={<PriceField value={row.original.offer?.price} />}
          />
        );
      },
    },
    {
      Header: `✏️ ${t('created')}`,
      accessor: 'created',
      Cell: ({ row }: Cell<OrderPreviewFragment>) => {
        return (
          <TextColumnTag
            value={
              <DateFormatter
                date={new Date(row.original.createdAt)}
                format="PP"
              />
            }
          />
        );
      },
    },
  ] as any[];
  return {
    columns,
  };
};
