import {
  Box,
  Button,
  CloseButton,
  Tooltip,
  UseDisclosureProps,
  useColorModeValue,
} from '@chakra-ui/react';
import React, { ReactNode, FC, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { SidebarContext } from '@app/contexts/SidebarContext';

import Content from './components/SidebarContent';

const Sidebar = () => {
  const { t } = useTranslation('navbar');
  const { toggleSidebar, onToggleSideBar } = useContext(SidebarContext);
  const [isHover, setHover] = useState<boolean>();
  const variantChange = '0.2s linear';

  const sidebarWidth = toggleSidebar ? '350px' : { base: 0, md: '74px' };
  const sidebarBg = useColorModeValue('white', 'navy.800');

  return (
    <Box
      display={{ base: toggleSidebar ? 'block' : 'none', md: 'block' }}
      h="100dvh"
      position="fixed"
      zIndex={15}>
      <Box
        bg={sidebarBg}
        boxShadow="wrapper"
        h="100dvh"
        minH="100%"
        overflowX="hidden"
        px={
          toggleSidebar ? { base: '16px', md: '20px' } : { base: 0, md: '16px' }
        }
        transition={variantChange}
        w={sidebarWidth}>
        <Content />
      </Box>
      <Box
        display={{ base: 'none', md: 'block' }}
        h="24px"
        position="absolute"
        right="-24px"
        top="calc(50% - 12px)">
        <Tooltip
          hasArrow
          label={toggleSidebar ? t('collapsSidebar') : t('expandSidebar')}
          placement="right">
          <Button
            _hover={{
              background: 'transparent',
            }}
            background="transparent"
            borderRadius="0px"
            display="flex"
            flexDirection="column"
            maxWidth="5px"
            minW="15px !important"
            p="0px"
            w="15px !important"
            onClick={onToggleSideBar}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}>
            <Box
              background="primary.green"
              borderRadius="4px"
              h="15px"
              mb="-2px"
              transform={
                isHover
                  ? !toggleSidebar
                    ? 'rotate(-18deg)'
                    : 'rotate(18deg)'
                  : 'none'
              }
              transition={variantChange}
              w="4px"
            />
            <Box
              background="primary.green"
              borderRadius="4px"
              h="15px"
              mt="-2px"
              transform={
                isHover
                  ? !toggleSidebar
                    ? 'rotate(18deg)'
                    : 'rotate(-18deg)'
                  : 'none'
              }
              transition={variantChange}
              w="4px"
            />
          </Button>
        </Tooltip>
      </Box>
    </Box>
  );
};

interface SidebarResponsiveProps extends UseDisclosureProps {
  children: ReactNode;
  width?: string;
  showCloseButton?: boolean;
}

export const SidebarResponsive: FC<SidebarResponsiveProps> = ({
  children,
  width = '25%',
  isOpen,
  onClose,
  showCloseButton = true,
}) => {
  if (!isOpen) {
    return null;
  }
  return (
    <Box
      bg="white.200"
      boxShadow="2xl"
      h="100dvh"
      minW="400px"
      overflow="hidden"
      position="fixed"
      right="0"
      top="0"
      w={width}
      zIndex={15}>
      {showCloseButton && (
        <CloseButton
          borderRadius="100px"
          position="absolute"
          right="20px"
          top="20px"
          onClick={onClose}
        />
      )}
      {children}
    </Box>
  );
};

export default Sidebar;
