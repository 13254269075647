import { Box, Flex, HStack, Text } from '@chakra-ui/react';
import { addDays } from 'date-fns';
import isEmpty from 'lodash/isEmpty';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { DateFormatter } from '@app/components/DateFormatter';
import { GoogleMapView } from '@app/components/GoogleMapView';
import { PriceField } from '@app/components/PriceField';
import { ReactComponent as FavoriteIcon } from '@app/icons/bookmark-icon.svg';
import { OrderOfferInformationMenu } from '@app/views/Orders/components/OrderOfferInformationMenu';
import { CategoryTag } from 'views/Requests/components/CategoriesTags/Tag';
import { MarkDownView } from '@app/components/MarkDownView';
import { OfferFragment } from '@app/api/schemas/offer.mongo.generated';
import { BlockHideWrapper } from '@app/components/Wrappers/BlockHideWrapper';

import EmptyState from './EmptyState';
import { InformationItem } from './InformationItem';
import { OfferPricesAccordion } from './OfferPricesAccordion';

interface InformationProps {
  offer?: Partial<OfferFragment>;
  originalOffer?: Partial<OfferFragment>;
  isOrder?: boolean;
}

export const Information: FC<InformationProps> = ({
  offer,
  originalOffer,
  isOrder = false,
}) => {
  const { t } = useTranslation(['tables']);
  const endDate = !!offer?.startDate
    ? addDays(new Date(offer?.startDate), Number(offer?.days))
    : null;
  const geetMapCenter = {
    lat: offer?.location?.coordinates?.coordinates?.[1],
    lng: offer?.location?.coordinates?.coordinates?.[0],
  };

  const isNoOffer = useMemo(() => {
    return (
      !offer?.startDate &&
      !offer?.title &&
      !offer?.description &&
      !offer?.activities?.length &&
      !offer?.price?.value &&
      !offer?.location
    );
  }, [
    offer?.activities?.length,
    offer?.description,
    offer?.location,
    offer?.price?.value,
    offer?.startDate,
    offer?.title,
  ]);

  const priceLabel = useMemo(() => {
    if (offer?.type) {
      return t('price') + ' ' + t(`offerType.${offer?.type}`, { ns: 'forms' });
    }
    return t('price');
  }, [offer?.type]);

  const hasPrices = useMemo(
    () => offer?.offerAddition?.length > 1,
    [offer?.offerAddition?.length],
  );

  return (
    <>
      <HStack alignItems="flex-start" justifyContent="space-between">
        {!!originalOffer ? (
          <Text
            as={Link}
            color="black.100"
            fontSize="24px"
            mb={'18px'}
            noOfLines={2}
            to={`/offers/${originalOffer?._id}`}>
            {offer?.title}
          </Text>
        ) : (
          <Text
            color="black.100"
            fontSize="24px"
            lineHeight="30px"
            mb={3}
            noOfLines={2}>
            {offer?.title}
          </Text>
        )}
        {!!offer?.likeCount && (
          <HStack alignItems="center" mt={1.5} spacing={1}>
            <Text color="grey.250" fontWeight={600}>
              {offer.likeCount}
            </Text>
            <FavoriteIcon />
          </HStack>
        )}
        {isOrder && <OrderOfferInformationMenu offer={originalOffer} />}
      </HStack>
      {!!offer?.description && (
        <Box borderBottom="1px" borderBottomColor="green.300" mb={3} pb={3}>
          <BlockHideWrapper height={82}>
            <MarkDownView
              color="brand.900"
              fontSize={'14px'}
              overflow="hidden"
              text={offer.description}
            />
          </BlockHideWrapper>
        </Box>
      )}
      {isNoOffer && (
        <Text color="grey.250" fontSize={16}>
          {t('offerDetailsEmpty')}
        </Text>
      )}
      {isNoOffer && <EmptyState />}
      {!hasPrices && !!offer.startDate && (
        <InformationItem title={t('date')}>
          <Text
            color="brand.900"
            fontSize="14px"
            fontWeight={600}
            noOfLines={2}>
            {offer?.startDate && (
              <>
                <DateFormatter date={new Date(offer?.startDate)} format="PP" />{' '}
                - {!!endDate && <DateFormatter date={endDate} format="PP" />}
              </>
            )}
          </Text>
        </InformationItem>
      )}
      {!hasPrices && !!offer.days && (
        <InformationItem title={t('nights')}>
          <Text color="brand.900" fontSize="14px" fontWeight={600}>
            {offer?.days}
          </Text>
        </InformationItem>
      )}
      {!hasPrices && !!Number(offer?.adults) && (
        <InformationItem title={t('adults')}>
          <Text color="brand.900" fontSize="14px" fontWeight={600}>
            {offer?.adults}
          </Text>
        </InformationItem>
      )}
      {!hasPrices && !!Number(offer?.children) && (
        <InformationItem title={t('children')}>
          <Text color="brand.900" fontSize="14px" fontWeight={600}>
            {offer?.children}
          </Text>
        </InformationItem>
      )}
      {!hasPrices && !!offer?.price?.value && (
        <InformationItem title={priceLabel}>
          <Text color="primary.green" fontSize="14px" fontWeight={600}>
            <PriceField
              type={offer?.priceEstimateType}
              value={
                offer?.price?.value
                  ? offer?.price
                  : { ...offer.price?.currency, value: 0 }
              }
            />
          </Text>
        </InformationItem>
      )}

      {hasPrices && (
        <Box mb={5}>
          <Text color="mainGray.100" fontSize="14px" fontWeight={400} mb={4}>
            {t('tripDates')}
          </Text>
          <OfferPricesAccordion offer={offer} />
        </Box>
      )}

      {!!offer?.location && (
        <>
          <Box borderRadius={5} mt="20px" overflow={'hidden'}>
            <GoogleMapView center={geetMapCenter} height="120px" width="100%" />
          </Box>
          <>
            {!!offer?.location?.description && (
              <Text color="grey.250" fontSize="12px" mt="15px">
                {offer?.location?.description}
              </Text>
            )}
          </>
        </>
      )}

      {!isEmpty(offer?.activities) && (
        <Box borderTop="1px" borderTopColor="green.300" my={3} py={3}>
          <Text color="mainGray.100" fontSize="14px" fontWeight={400} mb="16px">
            {t('activities')}
          </Text>
          <BlockHideWrapper>
            <Flex flexWrap="wrap" gap={2}>
              {offer?.activities?.map((activity) => (
                <CategoryTag key={activity?._id} details={activity?.details} />
              ))}
            </Flex>
          </BlockHideWrapper>
        </Box>
      )}
    </>
  );
};
