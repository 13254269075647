import { Box, VStack } from '@chakra-ui/react';
import React, { FC, useContext } from 'react';

import { SidebarContext } from '@app/contexts/SidebarContext';

interface SidebarMobileButtonProps {
  color?: string;
}

export const SidebarMobileButton: FC<SidebarMobileButtonProps> = ({
  color = 'grey.300',
}) => {
  const { onToggleSideBar } = useContext(SidebarContext);
  return (
    <VStack
      display={{ base: 'flex', md: 'none' }}
      spacing={1.5}
      w={6}
      onClick={onToggleSideBar}>
      <Box backgroundColor={color} h="2px" w="full" />
      <Box backgroundColor={color} h="2px" w="full" />
      <Box backgroundColor={color} h="2px" w="full" />
    </VStack>
  );
};
