import { Box } from '@chakra-ui/react';
import React, { useCallback } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Row } from 'react-table';
import { useNavigate, useParams } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';

import { Order } from '@app/api/gql/generated-types';
import { EmptyList } from '@app/components/EmptyList';
import { OrderInfoContent } from '@app/components/OrderInfoContent/OrderInfoContent';
import { SidebarResponsive } from '@app/components/Sidebar';
import { GenericTable } from '@app/components/Tables/GenericTable';
import {
  OrderPreviewFragmentDoc,
  useUpdateOrderMutation,
} from '@app/api/schemas/order.mongo.generated';
import {
  GetCountersDocument,
  GetCountersQuery,
} from '@app/api/schemas/account.mongo.generated';

import { useOrdersColumns } from '../../hooks/useOrdersColumns';
import { useOrdersData } from '../../hooks/useOrdersData';
import { TableFilter } from '../Filter';
import { TablesHeader } from '../TablesHeader';

export const OrderTable = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const client = useApolloClient();
  const { columns } = useOrdersColumns();
  const { t } = useTranslation('tables');
  const [updateMutation] = useUpdateOrderMutation();

  const {
    orders,
    form,
    onReset,
    fetchNextPage,
    hasNextPage,
    total,
    isReady,
    isLoading,
    statusCounts,
    isFiltered,
  } = useOrdersData({ pollInterval: 60000 });

  const updateCounters = useCallback(() => {
    client.cache.updateQuery<GetCountersQuery>(
      {
        query: GetCountersDocument,
      },
      (data) => ({
        ...data,
        getCounters: {
          ...data?.getCounters,
          totalUnreadOrders:
            data?.getCounters?.totalUnreadOrders > 0
              ? data?.getCounters?.totalUnreadOrders - 1
              : 0,
        },
      }),
    );
  }, []);

  const onRowClick = (row: Row<Order>) => {
    if (row?.original?._id === id) {
      navigate('/orders');
      return;
    }
    if (!row?.original?.isRead) {
      void updateMutation({
        variables: {
          input: {
            _id: row?.original?._id,
            isRead: true,
          },
        },
        update(cache, _result, { variables }) {
          cache.updateFragment<Order>(
            {
              id: `Order:${variables.input?._id}`,
              fragment: OrderPreviewFragmentDoc,
              fragmentName: 'OrderPreview',
            },
            (data) => {
              return {
                ...data,
                isRead: true,
              };
            },
          );
        },
      });
      updateCounters();
    }
    navigate(`/orders/${row?.original?._id}`);
  };

  const sidebarOnClose = useCallback(() => {
    navigate('/orders');
  }, [navigate]);

  return (
    <Box>
      <Box mb={{ base: 0, md: '30px' }}>
        <FormProvider {...form}>
          <TablesHeader statusCount={statusCounts} title={t('ordersTitle')}>
            <Box>
              {((!isFiltered && !!total) || isFiltered) && (
                <TableFilter
                  showReset={isFiltered}
                  statusCounts={statusCounts}
                  onReset={onReset}
                />
              )}
            </Box>
          </TablesHeader>
        </FormProvider>
      </Box>
      {!isLoading && !!total && (
        <GenericTable
          isStatusShow
          showIsRead
          activeRow={id}
          columns={columns}
          data={orders}
          hasMore={hasNextPage}
          onLoadMore={fetchNextPage}
          onRowClick={onRowClick}
        />
      )}
      {isReady && !total && (
        <EmptyList
          description={t('noOrderFindDesc')}
          maxDescWidth="375px"
          title={t('noOrderFind')}
        />
      )}

      {!!id && (
        <SidebarResponsive
          isOpen={!!id}
          showCloseButton={false}
          onClose={sidebarOnClose}>
          <OrderInfoContent key={id} orderId={id} onClose={sidebarOnClose} />
        </SidebarResponsive>
      )}
    </Box>
  );
};
