import Cookies from 'js-cookie';
import { useCallback } from 'react';

export const useCookies = () => {
  const getCookie = useCallback((key: string) => Cookies.get(key), []);

  const setCookie = useCallback((key: string, value: string) => {
    Cookies.set(key, JSON.stringify(value));
  }, []);

  return { getCookie, setCookie };
};
