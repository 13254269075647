import { Box, Text, Tooltip, VStack } from '@chakra-ui/react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { ReactComponent as BusinessSettingsIcon } from '@app/icons/new-business-settings-icon.svg';
import { ReactComponent as ProfileSettingsIcon } from '@app/icons/new-profile-settings-icon.svg';
import { ReactComponent as OffersIcon } from '@app/icons/new-offers-icon.svg';
import { ReactComponent as OrdersIcon } from '@app/icons/new-orders-icon.svg';
import { ReactComponent as ChatsIcon } from '@app/icons/new-chats-icon.svg';
import { ReactComponent as RequestsIcon } from '@app/icons/new-requests-icon.svg';
import { useWatchData } from '@app/hooks/useWatchData';
import { useAccountIsAdmin } from '@app/hooks/useAccountIsAdmin';
import { SidebarContext } from '@app/contexts/SidebarContext';

import { ListItem } from './ListItem';
import { EmojiIcon } from './EmojiIcon';

export const SidebarLinks = () => {
  const { isAdmin } = useAccountIsAdmin();
  const { requests, orders, chats } = useWatchData();
  const { toggleSidebar } = useContext(SidebarContext);

  const { t } = useTranslation('navbar');

  const adminChild = [];

  const links = [
    {
      name: t('requests'),
      to: '/requests',
      icon: <RequestsIcon />,
      count: requests,
    },
    {
      name: t('offers'),
      to: '/offers',
      icon: <OffersIcon />,
    },
    {
      name: t('orders'),
      to: '/orders',
      icon: <OrdersIcon />,
      count: orders,
    },

    {
      name: t('chat'),
      to: '/chat',
      icon: <ChatsIcon />,
      count: chats,
    },
    {
      name: t('profile'),
      to: '/profile-settings',
      icon: <ProfileSettingsIcon />,
    },
    {
      name: t('business'),
      to: '/business-settings',
      icon: <BusinessSettingsIcon />,
    },
  ];

  if (isAdmin) {
    links.push({
      name: t('admin'),
      to: '/admin',
      icon: (
        <EmojiIcon>
          <Text fontSize={18}>🛠️</Text>
        </EmojiIcon>
      ),
    });

    adminChild.push({
      name: t('agents'),
      to: '/admin/agents',
      icon: <BusinessSettingsIcon />,
    });
    adminChild.push({
      name: t('clients'),
      to: '/admin/clients',
      icon: <ProfileSettingsIcon />,
    });
    adminChild.push({
      name: t('sendPush'),
      to: '/admin/notifications/push',
      icon: (
        <EmojiIcon>
          <Text fontSize={18}>📲</Text>
        </EmojiIcon>
      ),
    });
  }

  return (
    <VStack alignItems="flex-start" gap="20px" w="full">
      {links.map((link, index) => (
        <Tooltip
          key={index}
          hasArrow
          isDisabled={toggleSidebar}
          label={link.name}
          placement="right">
          <Box
            as={NavLink}
            role="group"
            to={link.to}
            userSelect="none"
            w="full">
            {({ isActive }) => (
              <>
                <ListItem
                  count={link.count ?? null}
                  icon={link.icon}
                  isActive={isActive}
                  name={link.name}
                />
                {link.to === '/admin' && isActive && (
                  <VStack mt={4} spacing={3}>
                    {adminChild?.map((child, childIndex) => (
                      <Box
                        key={childIndex}
                        as={NavLink}
                        role="group"
                        to={child.to}
                        userSelect="none"
                        w="full">
                        {({ isActive: isActiveChild }) => (
                          <ListItem
                            isChild
                            count={child.count ?? null}
                            icon={child.icon}
                            isActive={isActiveChild}
                            name={child.name}
                          />
                        )}
                      </Box>
                    ))}
                  </VStack>
                )}
              </>
            )}
          </Box>
        </Tooltip>
      ))}
    </VStack>
  );
};
