import isNil from 'lodash/isNil';
import { useParams, useSearchParams } from 'react-router-dom';
import { BSON } from 'realm-web';
import omit from 'lodash/omit';

import {
  CreateOfferInput,
  OfferPriceEstimateType,
  OfferStatus,
  OfferType,
} from '@app/api/gql/generated-types';
import useCurrentAccount from '@app/hooks/useCurrentAccount';
import { useGetOfferQuery } from '@app/api/schemas/offer.mongo.generated';
import { useRequestQuery } from '@app/api/schemas/request.mongo.generated';

export const useInitialValues = () => {
  const { id } = useParams();
  const { account } = useCurrentAccount();
  const [searchParams] = useSearchParams();
  const requestId = searchParams.get('requestId');

  const { data: { getOffer: offer } = {}, loading: isLoading } =
    useGetOfferQuery({
      variables: {
        input: {
          id,
        },
      },
    });

  const { data: { request } = {} } = useRequestQuery({
    variables: {
      query: {
        _id: !!requestId ? new BSON.ObjectID(requestId) : null,
      },
    },
    skip: !requestId,
  });

  const initialValues: CreateOfferInput = {
    title: '',
    description: '',
    isPublic: !requestId,
    startDate: undefined,
    days: '',
    price: {
      value: null,
      currency: account?.settings?.currency,
    },
    tags: null,
    ...{ requestId },
    status: OfferStatus.DRAFT,
    activities: [],
    photo: null,
    adults: undefined,
    person: 1,
    type: !!request ? OfferType.TOUR : OfferType.PERSON,
    priceEstimateType: OfferPriceEstimateType.FINAL,
    location: null,
    children: undefined,
    offerAddition: [
      {
        startDate: undefined,
        days: '',
        price: {
          value: null,
          currency: account?.settings?.currency,
        },
        adults: undefined,
        children: undefined,
      },
    ],
  };
  const offerValues = {
    ...(offer && {
      ...offer,
      ...(offer?.requestId && { requestId: offer.requestId?._id }),
      ...(!!offer?.photo?.length && {
        photo: offer.photo?.map((item) => omit(item, ['__typename'])),
      }),
      startDate: new Date(offer?.startDate),
      days: Number(offer?.days),
      price: {
        value: offer?.price?.value,
        currency: !isNil(offer?.price?.currency)
          ? offer?.price?.currency
          : account?.settings?.currency,
      },
    }),
  };

  return {
    initialValues,
    isLoading,
    offer,
    offerValues,
  };
};
