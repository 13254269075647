import * as Types from '@/gql/__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type NotificationFragment = { __typename?: 'Notification', _id: any, text: string, title: string, createdAt: string | null, entityType: Types.NotificationEntityType | null, entityId: string | null, isRead: boolean | null, status: Types.NotificationStatus | null };

export type NotificationsQueryVariables = Types.Exact<{
  sortBy: Types.InputMaybe<Types.NotificationSortByInput>;
}>;


export type NotificationsQuery = { __typename?: 'Query', notifications: Array<{ __typename?: 'Notification', _id: any, text: string, title: string, createdAt: string | null, entityType: Types.NotificationEntityType | null, entityId: string | null, isRead: boolean | null, status: Types.NotificationStatus | null } | null> };

export type SendAccountsPushMutationVariables = Types.Exact<{
  input: Types.SendAccountsPushInput;
}>;


export type SendAccountsPushMutation = { __typename?: 'Mutation', sendAccountsPush: { __typename?: 'DefaultPayload', status: string } | null };

export type UpdateOneNotificationMutationVariables = Types.Exact<{
  query: Types.InputMaybe<Types.NotificationQueryInput>;
  set: Types.NotificationUpdateInput;
}>;


export type UpdateOneNotificationMutation = { __typename?: 'Mutation', updateOneNotification: { __typename?: 'Notification', _id: any, text: string, title: string, createdAt: string | null, entityType: Types.NotificationEntityType | null, entityId: string | null, isRead: boolean | null, status: Types.NotificationStatus | null } | null };

export const NotificationFragmentDoc = gql`
    fragment Notification on Notification {
  _id
  text
  title
  createdAt
  entityType
  entityId
  isRead
  status
}
    `;
export const NotificationsDocument = gql`
    query notifications($sortBy: NotificationSortByInput) {
  notifications(sortBy: $sortBy) {
    ...Notification
  }
}
    ${NotificationFragmentDoc}`;

/**
 * __useNotificationsQuery__
 *
 * To run a query within a React component, call `useNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsQuery({
 *   variables: {
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options);
      }
export function useNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options);
        }
export function useNotificationsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options);
        }
export type NotificationsQueryHookResult = ReturnType<typeof useNotificationsQuery>;
export type NotificationsLazyQueryHookResult = ReturnType<typeof useNotificationsLazyQuery>;
export type NotificationsSuspenseQueryHookResult = ReturnType<typeof useNotificationsSuspenseQuery>;
export type NotificationsQueryResult = Apollo.QueryResult<NotificationsQuery, NotificationsQueryVariables>;
export const SendAccountsPushDocument = gql`
    mutation sendAccountsPush($input: SendAccountsPushInput!) {
  sendAccountsPush(input: $input) {
    status
  }
}
    `;
export type SendAccountsPushMutationFn = Apollo.MutationFunction<SendAccountsPushMutation, SendAccountsPushMutationVariables>;

/**
 * __useSendAccountsPushMutation__
 *
 * To run a mutation, you first call `useSendAccountsPushMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendAccountsPushMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendAccountsPushMutation, { data, loading, error }] = useSendAccountsPushMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendAccountsPushMutation(baseOptions?: Apollo.MutationHookOptions<SendAccountsPushMutation, SendAccountsPushMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendAccountsPushMutation, SendAccountsPushMutationVariables>(SendAccountsPushDocument, options);
      }
export type SendAccountsPushMutationHookResult = ReturnType<typeof useSendAccountsPushMutation>;
export type SendAccountsPushMutationResult = Apollo.MutationResult<SendAccountsPushMutation>;
export type SendAccountsPushMutationOptions = Apollo.BaseMutationOptions<SendAccountsPushMutation, SendAccountsPushMutationVariables>;
export const UpdateOneNotificationDocument = gql`
    mutation updateOneNotification($query: NotificationQueryInput, $set: NotificationUpdateInput!) {
  updateOneNotification(query: $query, set: $set) {
    ...Notification
  }
}
    ${NotificationFragmentDoc}`;
export type UpdateOneNotificationMutationFn = Apollo.MutationFunction<UpdateOneNotificationMutation, UpdateOneNotificationMutationVariables>;

/**
 * __useUpdateOneNotificationMutation__
 *
 * To run a mutation, you first call `useUpdateOneNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneNotificationMutation, { data, loading, error }] = useUpdateOneNotificationMutation({
 *   variables: {
 *      query: // value for 'query'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateOneNotificationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneNotificationMutation, UpdateOneNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneNotificationMutation, UpdateOneNotificationMutationVariables>(UpdateOneNotificationDocument, options);
      }
export type UpdateOneNotificationMutationHookResult = ReturnType<typeof useUpdateOneNotificationMutation>;
export type UpdateOneNotificationMutationResult = Apollo.MutationResult<UpdateOneNotificationMutation>;
export type UpdateOneNotificationMutationOptions = Apollo.BaseMutationOptions<UpdateOneNotificationMutation, UpdateOneNotificationMutationVariables>;