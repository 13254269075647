import businessSettings from './businessSettings.json';
import forms from './forms.json';
import mainSettings from './mainSettings.json';
import workSettings from './workSettings.json';
import profileSettings from './profileSettings.json';
import tables from './tables.json';
import offersAndRequests from './offersAndRequests.json';
import modals from './modalsAndMenus.json';
import addOffer from './addOffer.json';
import offerDetails from './offerDetails.json';
import modalsAndMenus from './modalsAndMenus.json';
import navbar from './navbar.json';
import profile from './profile.json';
import validations from './validations.json';
import footer from './footer.json';
import tabs from './tabs.json';
import information from './information.json';
import notifications from './notifications.json';

export default {
  businessSettings,
  forms,
  mainSettings,
  workSettings,
  profileSettings,
  offersAndRequests,
  tables,
  modals,
  addOffer,
  offerDetails,
  modalsAndMenus,
  navbar,
  profile,
  validations,
  footer,
  tabs,
  information,
  notifications,
};
