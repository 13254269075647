import { AuthUser, FetchUserAttributesOutput } from 'aws-amplify/auth';
import { useContext } from 'react';

import { CurrentAccountContext } from '@app/providers/CurrentAccountProvider';
import { AccountFragment } from '@app/api/schemas/account.mongo.generated';
import { BusinessFragment } from '@app/api/schemas/business.mongo.generated';

interface CurrentAccountType {
  account: AccountFragment;
  business: BusinessFragment;
  user: AuthUser;
  userAttributes: FetchUserAttributesOutput;
}

export default (): CurrentAccountType => {
  const { account, business, user, userAttributes } = useContext(
    CurrentAccountContext,
  );

  return { account, business, user, userAttributes };
};
