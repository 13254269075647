import { OfferAddition } from '@app/api/gql/generated-types';
import useCurrentAccount from '@app/hooks/useCurrentAccount';

export const useAdditionalPriceValues = () => {
  const { account } = useCurrentAccount();
  const initialValues: OfferAddition = {
    startDate: undefined,
    days: '',
    price: {
      value: null,
      currency: account?.settings?.currency,
    },
    adults: undefined,
    children: undefined,
  };

  return {
    initialValues,
  };
};
