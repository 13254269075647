import { useContext, useEffect, useState } from 'react';

import { SidebarContext } from '@app/contexts/SidebarContext';

export const useMobile = () => {
  const { onCloseSidebar } = useContext(SidebarContext);
  const [showFilters, setShowFilters] = useState<boolean>(true);

  useEffect(() => {
    if (window.innerWidth < 768) {
      setShowFilters(false);
      onCloseSidebar();
    }
  }, []);

  return {
    showFilters,
    setShowFilters,
  };
};
