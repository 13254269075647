import React, { FC } from 'react';
import {
  Box,
  Button,
  Center,
  ModalHeader,
  UseDisclosureProps,
} from '@chakra-ui/react';

import useCurrentAccount from '@app/hooks/useCurrentAccount';
import { useCookies } from '@app/hooks/useCookies';
import { isGreetingModalOpenedKey } from '@app/layouts/MainLayout';

import Popup from '../Popup';

type CustomModalProps = UseDisclosureProps;

export const GreetingModal: FC<CustomModalProps> = ({ isOpen, onClose }) => {
  const { account } = useCurrentAccount();

  const { setCookie } = useCookies();

  const onGreetingSubmit = () => {
    setCookie(isGreetingModalOpenedKey, true);
    onClose();
  };
  return (
    <Popup
      closeOnOverlayClick
      hideCloseIcon={false}
      isOpen={isOpen}
      maxW={'550'}
      onClose={onGreetingSubmit}>
      <Box p="0px 40px 30px">
        <ModalHeader
          color="navy.700"
          fontSize="28px"
          fontWeight={700}
          lineHeight="40px"
          padding={['65px 20px 35px']}
          textAlign="center"
          width="100%">
          {account?.fullName}, welcome to the team of professionals!
        </ModalHeader>
        <Box color="mainGray.100" fontSize="16px" fontWeight={400}>
          Congratulations, your profile has been successfully created!
          <br />
          <br />
          You are now a part of our tourism marketplace, which brings together
          the best tourism managers from all over the world. Here you will find
          numerous useful tools and information for effective work.
          <br />
          <br />
          Explore all the capabilities of the platform, create unique tours and
          offers, attract new clients, and develop your business with us.
          <br />
          <br />
          Enjoy your work!
        </Box>
        <Center>
          <Button
            fontSize="sm"
            fontWeight="500"
            h="50"
            mb="5px"
            mt="40px"
            variant="brand"
            w="150px"
            onClick={onGreetingSubmit}>
            Close
          </Button>
        </Center>
      </Box>
    </Popup>
  );
};
