import * as Types from '@/gql/__generated__/types';

import { gql } from '@apollo/client';
import { ActivityFragmentDoc } from './activity.mongo.generated';
import { CharacteristicFragmentDoc } from './travelerCharacteristics.mongo.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RequestPriceFragment = { __typename?: 'RequestPrice', value: number, currency: { __typename?: 'Currency', code: string | null, symbol: string | null } | null };

export type RequestFragment = { __typename?: 'Request', _id: any, adults: number | null, children: number | null, createdAt: string | null, date: string | null, ownerId: string, status: Types.RequestStatus | null, description: string | null, updatedAt: string | null, childrenAge: Array<number | null> | null, startDate: string | null, endDate: string | null, nights: number | null, periodDays: number | null, location: { __typename?: 'RequestLocation', description: string, place_id: string } | null, owner: { __typename?: 'Account', _id: any, ownerId: string, fullName: string | null, firstName: string | null, lastName: string | null, activities: Array<{ __typename?: 'Activity', _id: any | null, color: string | null, parent: { __typename?: 'Activity', _id: any | null } | null, details: Array<{ __typename?: 'ActivitiesDetail', lang: string | null, title: string | null } | null> | null } | null> | null, travelerCharacteristics: Array<{ __typename?: 'TravelerCharacteristic', _id: any | null, parent: { __typename?: 'TravelerCharacteristic', _id: any | null } | null, details: Array<{ __typename?: 'TravelerCharacteristicsDetail', lang: string | null, title: string | null } | null> | null } | null> | null } | null, price: { __typename?: 'RequestPrice', value: number, currency: { __typename?: 'Currency', code: string | null, symbol: string | null } | null } | null, locationStart: { __typename?: 'RequestLocationStart', description: string, place_id: string } | null };

export type RequestPreviewFragment = { __typename?: 'Request', _id: any, adults: number | null, children: number | null, createdAt: string | null, date: string | null, isRead: boolean | null, offersCount: any | null, location: { __typename?: 'RequestLocation', description: string } | null, price: { __typename?: 'RequestPrice', value: number, currency: { __typename?: 'Currency', code: string | null, symbol: string | null } | null } | null };

export type RequestQueryVariables = Types.Exact<{
  query: Types.InputMaybe<Types.RequestQueryInput>;
}>;


export type RequestQuery = { __typename?: 'Query', request: { __typename?: 'Request', _id: any, adults: number | null, children: number | null, createdAt: string | null, date: string | null, ownerId: string, status: Types.RequestStatus | null, description: string | null, updatedAt: string | null, childrenAge: Array<number | null> | null, startDate: string | null, endDate: string | null, nights: number | null, periodDays: number | null, location: { __typename?: 'RequestLocation', description: string, place_id: string } | null, owner: { __typename?: 'Account', _id: any, ownerId: string, fullName: string | null, firstName: string | null, lastName: string | null, activities: Array<{ __typename?: 'Activity', _id: any | null, color: string | null, parent: { __typename?: 'Activity', _id: any | null } | null, details: Array<{ __typename?: 'ActivitiesDetail', lang: string | null, title: string | null } | null> | null } | null> | null, travelerCharacteristics: Array<{ __typename?: 'TravelerCharacteristic', _id: any | null, parent: { __typename?: 'TravelerCharacteristic', _id: any | null } | null, details: Array<{ __typename?: 'TravelerCharacteristicsDetail', lang: string | null, title: string | null } | null> | null } | null> | null } | null, price: { __typename?: 'RequestPrice', value: number, currency: { __typename?: 'Currency', code: string | null, symbol: string | null } | null } | null, locationStart: { __typename?: 'RequestLocationStart', description: string, place_id: string } | null } | null };

export type FindRequestQueryVariables = Types.Exact<{
  input: Types.InputMaybe<Types.FindRequestInput>;
}>;


export type FindRequestQuery = { __typename?: 'Query', findRequest: { __typename?: 'FindRequestPayload', hasNextPage: boolean | null, nextCreatedAt: string | null, nextId: string | null, total: number | null, totalNew: number | null, items: Array<{ __typename?: 'Request', _id: any, adults: number | null, children: number | null, createdAt: string | null, date: string | null, isRead: boolean | null, offersCount: any | null, location: { __typename?: 'RequestLocation', description: string } | null, price: { __typename?: 'RequestPrice', value: number, currency: { __typename?: 'Currency', code: string | null, symbol: string | null } | null } | null } | null> | null } | null };

export type SetReadRequestMutationVariables = Types.Exact<{
  input: Types.InputMaybe<Types.UpdateRequestRead>;
}>;


export type SetReadRequestMutation = { __typename?: 'Mutation', setReadRequest: { __typename?: 'DefaultPayload', status: string } | null };

export const RequestPriceFragmentDoc = gql`
    fragment RequestPrice on RequestPrice {
  value
  currency {
    code
    symbol
  }
}
    `;
export const RequestFragmentDoc = gql`
    fragment Request on Request {
  _id
  adults
  children
  createdAt
  date
  location {
    description
    place_id
  }
  owner {
    _id
    ownerId
    fullName
    firstName
    lastName
    activities {
      ...Activity
    }
    travelerCharacteristics {
      ...Characteristic
    }
  }
  ownerId
  price {
    ...RequestPrice
  }
  status
  description
  updatedAt
  locationStart {
    description
    place_id
  }
  childrenAge
  startDate
  endDate
  nights
  periodDays
}
    ${ActivityFragmentDoc}
${CharacteristicFragmentDoc}
${RequestPriceFragmentDoc}`;
export const RequestPreviewFragmentDoc = gql`
    fragment RequestPreview on Request {
  _id
  adults
  children
  createdAt
  date
  isRead
  location {
    description
  }
  price {
    ...RequestPrice
  }
  offersCount
}
    ${RequestPriceFragmentDoc}`;
export const RequestDocument = gql`
    query Request($query: RequestQueryInput) {
  request(query: $query) {
    ...Request
  }
}
    ${RequestFragmentDoc}`;

/**
 * __useRequestQuery__
 *
 * To run a query within a React component, call `useRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequestQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useRequestQuery(baseOptions?: Apollo.QueryHookOptions<RequestQuery, RequestQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RequestQuery, RequestQueryVariables>(RequestDocument, options);
      }
export function useRequestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RequestQuery, RequestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RequestQuery, RequestQueryVariables>(RequestDocument, options);
        }
export function useRequestSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<RequestQuery, RequestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RequestQuery, RequestQueryVariables>(RequestDocument, options);
        }
export type RequestQueryHookResult = ReturnType<typeof useRequestQuery>;
export type RequestLazyQueryHookResult = ReturnType<typeof useRequestLazyQuery>;
export type RequestSuspenseQueryHookResult = ReturnType<typeof useRequestSuspenseQuery>;
export type RequestQueryResult = Apollo.QueryResult<RequestQuery, RequestQueryVariables>;
export const FindRequestDocument = gql`
    query FindRequest($input: FindRequestInput) {
  findRequest(input: $input) {
    hasNextPage
    items {
      ...RequestPreview
    }
    nextCreatedAt
    nextId
    total
    totalNew
  }
}
    ${RequestPreviewFragmentDoc}`;

/**
 * __useFindRequestQuery__
 *
 * To run a query within a React component, call `useFindRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindRequestQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindRequestQuery(baseOptions?: Apollo.QueryHookOptions<FindRequestQuery, FindRequestQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindRequestQuery, FindRequestQueryVariables>(FindRequestDocument, options);
      }
export function useFindRequestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindRequestQuery, FindRequestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindRequestQuery, FindRequestQueryVariables>(FindRequestDocument, options);
        }
export function useFindRequestSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FindRequestQuery, FindRequestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindRequestQuery, FindRequestQueryVariables>(FindRequestDocument, options);
        }
export type FindRequestQueryHookResult = ReturnType<typeof useFindRequestQuery>;
export type FindRequestLazyQueryHookResult = ReturnType<typeof useFindRequestLazyQuery>;
export type FindRequestSuspenseQueryHookResult = ReturnType<typeof useFindRequestSuspenseQuery>;
export type FindRequestQueryResult = Apollo.QueryResult<FindRequestQuery, FindRequestQueryVariables>;
export const SetReadRequestDocument = gql`
    mutation SetReadRequest($input: UpdateRequestRead) {
  setReadRequest(input: $input) {
    status
  }
}
    `;
export type SetReadRequestMutationFn = Apollo.MutationFunction<SetReadRequestMutation, SetReadRequestMutationVariables>;

/**
 * __useSetReadRequestMutation__
 *
 * To run a mutation, you first call `useSetReadRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetReadRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setReadRequestMutation, { data, loading, error }] = useSetReadRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetReadRequestMutation(baseOptions?: Apollo.MutationHookOptions<SetReadRequestMutation, SetReadRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetReadRequestMutation, SetReadRequestMutationVariables>(SetReadRequestDocument, options);
      }
export type SetReadRequestMutationHookResult = ReturnType<typeof useSetReadRequestMutation>;
export type SetReadRequestMutationResult = Apollo.MutationResult<SetReadRequestMutation>;
export type SetReadRequestMutationOptions = Apollo.BaseMutationOptions<SetReadRequestMutation, SetReadRequestMutationVariables>;