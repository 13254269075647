import { Location } from 'react-router';

import { ActivityFragment } from './schemas/activity.mongo.generated';

export enum Role {
  CLIENT = 'client',
  MANAGER = 'manager',
  ADMIN = 'admin',
}

export enum NotificationType {
  REQUEST = 'REQUEST',
  ORDER = 'ORDER',
}

export interface NotificationItem {
  description: string;
  createdAt: Date;
  id: string;
  title: string;
  type: NotificationType;
}

export enum OfferTabs {
  OfferPreview = 'offer',
  RequestDetails = 'request',
  OrderPreview = 'order',
}
export enum RequestTabs {
  RequestPreview = 'request',
  MyOffers = 'myOffers',
}

export interface ChatLocation<State> extends Omit<Location, 'state'> {
  state: State;
}

export interface GroupedOption {
  readonly label: string;
  readonly options: readonly ActivityFragment[];
}
