import { HStack, IconButton } from '@chakra-ui/react';
import addDays from 'date-fns/addDays';
import React, { FC, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { DateFormatter } from '@app/components/DateFormatter';
import { PriceField } from '@app/components/PriceField';
import { ReactComponent as EditIcon } from '@app/icons/offer-pen-icon.svg';
import { ReactComponent as RemoveIcon } from '@app/icons/delete-icon.svg';

interface OfferPriceLineProps {
  name?: string;
  isFirst?: boolean;
  onEdit: () => void;
  onRemove: () => void;
}

export const OfferPriceLine: FC<OfferPriceLineProps> = ({
  name,
  isFirst = false,
  onEdit,
  onRemove,
}) => {
  const { getValues } = useFormContext();

  const [price, startDate, days, adults, children] = useMemo(
    () =>
      getValues([
        `${name}price`,
        `${name}startDate`,
        `${name}days`,
        `${name}adults`,
        `${name}children`,
      ]),
    [getValues, name],
  );

  const endDate = useMemo(() => {
    if (!startDate && !days) {
      return;
    }
    return addDays(new Date(startDate), Number(days));
  }, [days, startDate]);

  return (
    <HStack color="primary.green" fontSize={14} py={1} spacing={3}>
      <HStack _hover={{ opacity: 0.85 }} cursor="pointer" onClick={onEdit}>
        {!!startDate && !!endDate && (
          <>
            <DateFormatter date={new Date(startDate)} /> -{' '}
            <DateFormatter date={endDate} />;{' '}
          </>
        )}
        {!!price && (
          <>
            <PriceField value={price} />;{' '}
          </>
        )}
        ;
        {!!adults && (
          <>
            {adults}

            {!!children && <>+{children}</>}
          </>
        )}
      </HStack>
      <HStack alignItems="center" spacing={1}>
        <IconButton
          aria-label="Edit offer"
          color="grey.250"
          size="xs"
          variant="icon"
          onClick={onEdit}>
          <EditIcon height={18} width={18} />
        </IconButton>
        {!isFirst && (
          <IconButton
            aria-label="Remove offer"
            color="grey.250"
            size="xs"
            variant="icon"
            onClick={onRemove}>
            <RemoveIcon height={22} width={22} />
          </IconButton>
        )}
      </HStack>
    </HStack>
  );
};
