import React, { useCallback, useMemo } from 'react';
import {
  Badge,
  Box,
  HStack,
  IconButton,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import formatRelative from 'date-fns/formatRelative';
import enGB from 'date-fns/locale/en-GB';
import uk from 'date-fns/locale/uk';

import {
  useNotificationsQuery,
  useUpdateOneNotificationMutation,
} from '@app/api/schemas/notification.mongo.generated';
import { ReactComponent as Icon } from '@app/icons/notification-bell.svg';
import {
  Notification,
  NotificationEntityType,
  NotificationSortByInput,
} from '@app/api/gql/generated-types';

const entityLink = {
  [NotificationEntityType.CHAT]: 'chat',
  [NotificationEntityType.OFFER]: 'offers',
  [NotificationEntityType.REQUEST]: 'requests',
  [NotificationEntityType.ORDER]: 'orders',
};

const locale = {
  ...enGB,
  ...uk,
};

const now = new Date();

export const NotificationWidget = () => {
  const { t } = useTranslation(['notifications']);
  const { data: { notifications } = {} } = useNotificationsQuery({
    variables: {
      sortBy: NotificationSortByInput.CREATEDAT_DESC,
    },
    fetchPolicy: 'network-only',
    pollInterval: 60000,
  });

  const [update] = useUpdateOneNotificationMutation();

  const unreadCount = useMemo(
    () => notifications?.filter((item) => !item?.isRead)?.length,
    [notifications],
  );

  const handleSetRead = useCallback(
    async (item: Notification) => {
      if (!item?.isRead) {
        await update({
          variables: {
            query: {
              _id: item?._id,
            },
            set: {
              isRead: true,
            },
          },
        });
      }
    },
    [update],
  );

  return (
    <Box position="absolute" right={10} top="30px" zIndex={13}>
      <Popover placement="bottom-end">
        <PopoverTrigger>
          <Box position="relative">
            <IconButton
              _hover={{
                backgroundColor: 'transparent',
              }}
              aria-label="Notifications"
              backgroundColor="gray.100"
              icon={<Icon height={30} width={30} />}
              variant="icon"
            />
            {!!unreadCount && (
              <Badge
                alignItems="center"
                backgroundColor="red"
                color="white"
                display="flex"
                h={4}
                justifyContent="center"
                p="2px"
                pl="4px"
                position="absolute"
                pr="4px"
                right="-2px"
                top="-4px"
                w={4}>
                {unreadCount}
              </Badge>
            )}
          </Box>
        </PopoverTrigger>
        {!!notifications?.length && (
          <PopoverContent
            backgroundColor="white"
            border="0 none"
            boxShadow="0px 8px 24px rgba(66, 66, 66, 0.16)"
            maxH="80dvh"
            overflowY="auto"
            px={2}
            py={2}>
            {notifications?.map((item) => (
              <Box
                key={item?._id}
                _hover={{
                  backgroundColor: 'gray.100',
                }}
                as={Link}
                borderRadius={3}
                cursor="pointer"
                p={2}
                to={`/${entityLink[item?.entityType]}/${item?.entityId}`}
                transition="all 0.2s"
                onClick={() => handleSetRead(item)}>
                <Text fontWeight={item?.isRead ? 400 : 600}>
                  {t(`${item?.text}.emoji`)} {t(`${item?.text}.text`)}
                </Text>
                <Text
                  color="mainGray.300"
                  noOfLines={2}
                  opacity={item?.isRead ? 0.8 : 1}>
                  {item?.title}
                </Text>
                <HStack mt={1}>
                  <Text color="primary.green" fontSize={12} fontWeight={700}>
                    {formatRelative(new Date(item?.createdAt), now, { locale })}
                  </Text>
                </HStack>
              </Box>
            ))}
          </PopoverContent>
        )}
      </Popover>
    </Box>
  );
};
