import { Box } from '@chakra-ui/react';
import React from 'react';

import { NotificationWidget } from '@app/components/NotificationWidget';

import { OrderTable } from './components/Table';

export const Orders = () => {
  return (
    <Box pt={{ base: 0, md: '30px' }}>
      <NotificationWidget />
      <OrderTable />
    </Box>
  );
};
