import { Tooltip, VStack } from '@chakra-ui/react';
import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

// import { SearchBar } from '@app/components/SearchBar';
import { SidebarLinks } from '@app/components/Sidebar/components/Links';
import { ReactComponent as SettingsIcon } from '@app/icons/new-settings-icon.svg';
import { ReactComponent as Help } from '@app/icons/new-help-icon.svg';
import { SidebarContext } from '@app/contexts/SidebarContext';

import { HeaderMenu } from './HeaderMenu';
import { ListItem } from './ListItem';
import UserMenu from './UserMenu';
// import { OffersNotification } from './OffersNotification';

interface SidebarContentProps {
  isHover?: boolean;
}

const SidebarContent: FC<SidebarContentProps> = ({ isHover = false }) => {
  const { t } = useTranslation(['navbar']);
  const { toggleSidebar } = useContext(SidebarContext);
  return (
    <VStack
      borderRadius="30px"
      h="full"
      justifyContent="space-between"
      opacity={isHover ? 0.5 : 1}
      transition="opacity 0.15s linear">
      <VStack
        alignItems={toggleSidebar ? 'center' : 'flex-start'}
        gap="30px"
        pt="25px"
        w="full">
        <HeaderMenu />
        {/* <SearchBar /> */}
        <SidebarLinks />
      </VStack>
      {/* <VStack flex="1 0 auto" justifyContent="center" w="100%">
        {toggleSidebar && <OffersNotification />}
      </VStack> */}
      <VStack
        alignItems={!toggleSidebar ? 'center' : 'flex-start'}
        gap="22px"
        pb="20px"
        w="full">
        <Tooltip
          hasArrow
          isDisabled={toggleSidebar}
          label={t('settings')}
          placement="right">
          <NavLink to="/settings">
            {({ isActive }) => (
              <ListItem
                icon={<SettingsIcon />}
                isActive={isActive}
                name={t('settings')}
              />
            )}
          </NavLink>
        </Tooltip>
        <Tooltip
          hasArrow
          isDisabled={toggleSidebar}
          label={t('help')}
          placement="right">
          <NavLink to="/help">
            {({ isActive }) => (
              <ListItem icon={<Help />} isActive={isActive} name={t('help')} />
            )}
          </NavLink>
        </Tooltip>
        <UserMenu />
      </VStack>
    </VStack>
  );
};

export default SidebarContent;
