import {
  Menu,
  MenuButton,
  Avatar,
  MenuList,
  Flex,
  MenuItem,
  Button,
  Text,
  HStack,
  Box,
  VStack,
} from '@chakra-ui/react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import useCurrentAccount from '@app/hooks/useCurrentAccount';
import { lngs } from '@app/translations/translations';
import { useSignOut } from '@app/hooks/useSignOut';
import { ReactComponent as Arrows } from '@app/icons/arrows-up-down-icon.svg';
import { ReactComponent as ExitIcon } from '@app/icons/exit-icon.svg';
import { SidebarContext } from '@app/contexts/SidebarContext';

const imageServer = process.env.REACT_APP_AMAZON_IMAGE_SERVER;

const UserMenu = () => {
  const { signOut } = useSignOut();
  const { account } = useCurrentAccount();
  const { i18n, t } = useTranslation(['navbar', 'modalsAndMenus', 'profile']);
  const { toggleSidebar } = useContext(SidebarContext);
  const url =
    account?.avatar?.key &&
    `${imageServer}/${account?.avatar?.key}?width=200&format=webp`;
  return (
    <Box mt="20px">
      <Menu offset={[0, 10]}>
        <MenuButton
          _hover={{
            color: 'primary.green',
          }}
          color="secondaryGray.600"
          px={toggleSidebar ? '0px' : '10px'}
          w="full">
          <HStack alignItems="center" justifyContent="space-between">
            <HStack alignItems="center">
              <Avatar
                _hover={{ cursor: 'pointer' }}
                bg="primary.green"
                borderRadius="50%"
                color="white"
                h="40px"
                name={account?.fullName}
                size="sm"
                src={url}
                w="40px"
              />
              {toggleSidebar && (
                <VStack alignItems="flex-start" spacing={0}>
                  <Text>{account?.fullName}</Text>
                  <Text color="grey.250" fontSize={12}>
                    {account?.business?.companyName}
                  </Text>
                </VStack>
              )}
            </HStack>
            {toggleSidebar && <Arrows />}
          </HStack>
        </MenuButton>

        <MenuList
          backgroundColor="secondaryGray.300"
          border="none"
          borderRadius="0"
          maxH="450px"
          mt="10px"
          overflowY="auto"
          p="13px 0"
          w="310px">
          <Flex mb="0px" w="100%">
            <Text
              borderBottom="1px solid"
              borderColor="white"
              color="secondaryGray.900"
              fontSize="sm"
              fontWeight="700"
              pb="10px"
              ps="20px"
              pt="16px"
              w="100%">
              👋&nbsp; {t('hello', { ns: 'profile' })}, {account?.firstName}
            </Text>
          </Flex>
          <HStack m="15px 12px 15px">
            {Object.keys(lngs).map((lng) => (
              <Button
                key={lng}
                isDisabled={i18n.resolvedLanguage === lng}
                p="2px 0"
                textTransform="uppercase"
                variant="link"
                onClick={async () => {
                  await i18n.changeLanguage(lng);
                }}>
                {lngs[lng]?.nativeName}
              </Button>
            ))}
          </HStack>

          <VStack flexDirection="column" px="5px" py="10px">
            <MenuItem
              bg="none"
              borderRadius="8px"
              color="red.400"
              icon={
                <Box color="primary.green">
                  <ExitIcon height={25} width={25} />
                </Box>
              }
              iconSpacing={1}
              px="14px"
              onClick={signOut}>
              <Text
                as={Link}
                color="secondaryGray.900"
                fontSize="sm"
                to="/logout">
                {t('logOut', { ns: 'modalsAndMenus' })}
              </Text>
            </MenuItem>
          </VStack>
        </MenuList>
      </Menu>
    </Box>
  );
};

export default UserMenu;
