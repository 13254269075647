import { mode } from '@chakra-ui/theme-tools';
export const globalStyles = {
  colors: {
    brand: {
      200: '#0066BF',
      300: '#2B3674',
      400: '#014189',
      500: '#422AFB',
      600: '#3311DB',
      700: '#02044A',
      800: '#190793',
      900: '#001E42',
    },
    secondaryGray: {
      100: '#DDDDDD',
      200: '#E1E9F8',
      300: '#FAFAFA',
      400: '#E9EDF7',
      500: '#748398',
      600: '#A3AED0',
      700: '#707EAE',
      800: 'rgba(112, 144, 176, 0.12)',
      850: 'rgba(112, 144, 176, 0.02)',
      900: '#001E42',
    },
    mainGray: {
      100: '#787A89',
      200: '#EAEAEA',
      300: '#787979',
      400: '#EFEFEF',
      500: '#32323D',
    },
    red: {
      100: '#FEEFEE',
      200: '#E83535',
      500: '#EE5D50',
      600: '#E31A1A',
      700: 'rgba(238, 93, 80, 0.1)',
    },
    blue: {
      50: '#EFF4FB',
      200: '#B1D6D5',
      100: '#1b2559',
      800: '#183D3C',
    },
    orange: {
      100: '#FFF6DA',
      500: '#FFB547',
      600: '#D55C14',
      700: '#BB4913',
    },
    green: {
      100: '#E6FAF5',
      200: 'rgba(5, 205, 153, 0.1)',
      300: '#F3F8F7',
      400: '#01B574',
      600: '#317A79',
      700: '#78B7B7',
    },
    navy: {
      50: '#d0dcfb',
      100: '#aac0fe',
      200: '#a3b9f8',
      300: '#728fea',
      400: '#3652ba',
      500: '#1b3bbb',
      600: '#19191C',
      700: '#19172F',
      800: '#111c44',
      900: '#0b1437',
    },
    gray: {
      100: '#F5F5F5',
      200: '#A0A0A0',
      300: '#B6B6BE',
    },
    purple: {
      300: 'rgba(67, 24, 255, 0.28)',
      500: '#4318FF',
    },
    shadow: {
      100: 'rgba(112, 144, 176, 0.08)',
    },
    // new colors
    primary: {
      green: '#3D9897',
      red: '#FF2A2A',
      orange: '#EA7C35',
      blue: '#50CAE4',
    },
    black: {
      100: '#000000',
      200: '#282828',
      145: 'rgba(0,0,0, .45)',
    },
    white: {
      0: '#FFFFFF',
      100: '#F5F7FE',
      200: '#FCFDFF',
    },
    grey: {
      100: '#FAFAFA',
      150: '#EAEAEA',
      200: '#A0A0A0',
      250: '#787A89',
      300: '#484954',
      350: '#D9D9D9',
    },
    backgrounds: {
      green30: 'rgba(177, 214, 213, 0.3)',
    },
  },
  styles: {
    global: (props) => ({
      body: {
        height: '100%',
        overflowY: 'auto',
        bg: mode('white', 'navy.900')(props),
        fontFamily: "'Open Sans', sans-serif",
        letterSpacing: '-0.5px',
      },
      input: {
        color: 'gray.700',
      },
      html: {
        fontFamily: "'Open Sans', sans-serif",
        overflowY: 'hidden',
        height: '100%',
      },
      table: {
        fontFamily: "'Open Sans', sans-serif",
      },
    }),
  },
  shadows: {
    wrapper: '14px 0px 20px 4px rgba(0, 0, 0, 0.05)',
  },
};
