import { Box, Center, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import React from 'react';
import {
  UseTableOptions,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';

import { LoadMoreButton } from '../LoadMoreButton';

import { TableSkeleton } from './TableSkeleton';

export const GenericTable = <T,>({
  columns,
  data = [],
  onRowClick,
  isLoading = false,
  hasMore = false,
  isStatusShow = false,
  activeRow = null,
  showIsRead = false,
  onLoadMore,
}: UseTableOptions<Partial<T>> & {
  onRowClick: (val: T) => any;
  isLoading: boolean;
  hasMore?: boolean;
  isStatusShow?: boolean;
  activeRow?: string;
  showIsRead?: boolean;
  onLoadMore?: () => Promise<void>;
}) => {
  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    tableInstance;

  if (isLoading) {
    return <TableSkeleton />;
  }

  return (
    <Box w="full">
      <Box mx="15px">
        <Table
          {...getTableProps()}
          color="gray.500"
          mb="24px"
          variant="bordered">
          <Thead position="sticky" top={0} zIndex={1}>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, trIndex) => (
                  <Th
                    key={trIndex}
                    borderBottom="1px"
                    borderColor="mainGray.400"
                    fontWeight={600}
                    padding="20px 15px !important"
                    pe="10px"
                    textAlign={column?.textAlign ?? 'left'}
                    textTransform="none"
                    whiteSpace="nowrap">
                    {column.render('Header')}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {rows.map((row, index) => {
              prepareRow(row);
              const rowBackground = () => {
                if (row?.original?._id === activeRow) {
                  return 'white';
                }
                return showIsRead && !row.original?.isRead
                  ? 'green.300'
                  : 'secondaryGray.300';
              };
              return (
                <Tr
                  {...row.getRowProps()}
                  key={index}
                  _hover={{
                    opacity: 0.85,
                  }}
                  backgroundColor={rowBackground()}
                  borderBottom="1px"
                  borderBottomColor="grey.150"
                  cursor="pointer"
                  transition="all 0.2s"
                  onClick={() => onRowClick(row)}>
                  {row.cells.map((cell, trIndex) => {
                    return (
                      <Td
                        {...cell.getCellProps()}
                        key={trIndex}
                        borderColor="transparent"
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        padding={
                          isStatusShow
                            ? '9px 20px 9px 15px'
                            : '16px 30px !important'
                        }
                        position="relative">
                        {cell.render('Cell')}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Box>
      {hasMore && (
        <Center mb="20px">
          <LoadMoreButton
            fetchNextPage={onLoadMore}
            hasNextPage={true}
            isFetchingNextPage={isLoading}
            isLoading={isLoading}
          />
        </Center>
      )}
    </Box>
  );
};
