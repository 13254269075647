import { Box } from '@chakra-ui/react';
import React, { KeyboardEvent } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { InputPassword } from '@app/components/FormElements/InputPassword';
import { FormField } from '@app/components/formInputs/FormField';
import { useAuth } from '@app/hooks/useAuth';
import { Submit } from '@app/views/Login/components/LoginForm/Submit';
import { useErrorNotification } from '@app/hooks/useErrorNotification';

import { initialValues, SignUpFormProps } from '../../initialValues';
import { useValidationSchema } from '../../useValidationSchema';

const SignUpForm = () => {
  const toast = useErrorNotification();
  const resolver = useValidationSchema();
  const { t } = useTranslation('forms');
  const { handleSignUp } = useAuth();
  const form = useForm({
    defaultValues: initialValues,
    resolver,
  });
  const navigate = useNavigate();
  const onSubmit = async (values: SignUpFormProps) => {
    try {
      const { nextStep } = await handleSignUp({
        email: values.email,
        password: values.password,
      });
      if (nextStep.signUpStep === 'CONFIRM_SIGN_UP') {
        navigate('/verification', { state: { username: values.email } });
      }
    } catch (error) {
      toast(error);
    }
  };

  const onkeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      void form.handleSubmit(onSubmit)();
    }
  };

  return (
    <FormProvider {...form}>
      <FormField
        isRequired
        fontSize="sm"
        fontWeight="500"
        mb="25px"
        ms={{ base: '0px', md: '0px' }}
        name="email"
        placeholder={t('email')}
        size="lg"
        type="email"
        variant="auth"
        onKeyDown={onkeyDown}
      />

      <FormField
        Component={InputPassword}
        mb="25px"
        name="password"
        placeholder={t('password')}
        variant="auth"
        onKeyDown={onkeyDown}
      />

      <Box mt="15px">
        <Submit title={t('signUp')} onSubmit={onSubmit} />
      </Box>
    </FormProvider>
  );
};

export default SignUpForm;
