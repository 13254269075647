import { CloseIcon } from '@chakra-ui/icons';
import { Box, Fade, HStack, Text } from '@chakra-ui/react';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  FindOrdersInputFilter,
  StatusCount,
} from '@app/api/gql/generated-types';
import { DatePickerRange } from '@app/components/Datepicker/DatePickerRange';
import Label from '@app/components/FormElements/Label';
import { FormField } from '@app/components/formInputs/FormField';
import { ResetFilterButton } from '@app/components/Filter/ResetFilterButton';

import { SelectStatus } from './SelectStatus';

interface TableFilterProps {
  onReset: () => void;
  showReset?: boolean;
  statusCounts: StatusCount[];
}

export const TableFilter: FC<TableFilterProps> = ({
  onReset,
  showReset = false,
  statusCounts = [],
}) => {
  const { t } = useTranslation('tables');

  return (
    <HStack
      mb={{ base: 5, md: 0 }}
      mr="60px"
      position="relative"
      spacing="30px"
      zIndex={10}>
      <HStack
        alignItems="center"
        spacing={{ base: 2, md: '15px' }}
        wrap={{ base: 'wrap', xl: 'nowrap' }}>
        <Box minW="250px">
          <SelectStatus label={t('status')} statusCounts={statusCounts} />
        </Box>
        <Box w="210px">
          <DatePickerRange<FindOrdersInputFilter>
            formControlProps={{ flex: 1 }}
            label={t('tripDate')}
            name={'dates'}
            placement="bottom-start"
          />
        </Box>
        <Box>
          <Label color="grey.300" fontSize={14}>
            {t('price')}
          </Label>
          <HStack alignItems={'flex-end'}>
            <FormField
              name={'price.from'}
              placeholder={'0'}
              type="number"
              w="65px"
            />
            <FormField
              name={'price.to'}
              placeholder={'5000'}
              type="number"
              w="65px"
            />
          </HStack>
        </Box>
        {showReset && <ResetFilterButton onClick={onReset} />}
      </HStack>
    </HStack>
  );
};
