import * as Types from '@/gql/__generated__/types';

import { gql } from '@apollo/client';
import { OfferPriceFragmentDoc, OfferFragmentDoc } from './offer.mongo.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ChatPreviewFragment = { __typename?: 'Chat', _id: any, title: string | null, unreadCount: number | null, updatedAt: string | null, lastMessage: { __typename?: 'Message', content: string | null, createdAt: string | null, attachments: Array<{ __typename?: 'MessageAttachment', filename: string, type: Types.MessageAttachmentType, url: string } | null> | null, reference: { __typename?: 'MessageReference', offerId: { __typename?: 'Offer', title: string, photo: Array<{ __typename?: 'OfferPhoto', bucket: string | null, key: string | null, location: string | null } | null> | null } | null, orderId: { __typename?: 'Order', startAt: string | null, endAt: string | null } | null, requestId: { __typename?: 'Request', date: string | null, location: { __typename?: 'RequestLocation', description: string, place_id: string } | null } | null } | null } | null, participantsExcludingSelf: Array<{ __typename?: 'Account', _id: any, isOnline: boolean | null, lastOnlineDate: string | null, firstName: string | null, fullName: string | null, lastName: string | null, avatar: { __typename?: 'AccountAvatar', key: string | null } | null } | null> | null };

export type FindChatsQueryVariables = Types.Exact<{
  input: Types.InputMaybe<Types.FindChatsInput>;
}>;


export type FindChatsQuery = { __typename?: 'Query', findChats: { __typename?: 'FindChatsPayload', hasNextPage: boolean | null, nextCreatedAt: string | null, nextId: string | null, total: number | null, items: Array<{ __typename?: 'Chat', _id: any, title: string | null, unreadCount: number | null, updatedAt: string | null, lastMessage: { __typename?: 'Message', content: string | null, createdAt: string | null, attachments: Array<{ __typename?: 'MessageAttachment', filename: string, type: Types.MessageAttachmentType, url: string } | null> | null, reference: { __typename?: 'MessageReference', offerId: { __typename?: 'Offer', title: string, photo: Array<{ __typename?: 'OfferPhoto', bucket: string | null, key: string | null, location: string | null } | null> | null } | null, orderId: { __typename?: 'Order', startAt: string | null, endAt: string | null } | null, requestId: { __typename?: 'Request', date: string | null, location: { __typename?: 'RequestLocation', description: string, place_id: string } | null } | null } | null } | null, participantsExcludingSelf: Array<{ __typename?: 'Account', _id: any, isOnline: boolean | null, lastOnlineDate: string | null, firstName: string | null, fullName: string | null, lastName: string | null, avatar: { __typename?: 'AccountAvatar', key: string | null } | null } | null> | null } | null> | null } | null };

export type GetChatQueryVariables = Types.Exact<{
  input: Types.InputMaybe<Types.GetChatInput>;
}>;


export type GetChatQuery = { __typename?: 'Query', getChat: { __typename?: 'Chat', _id: any, title: string | null, unreadCount: number | null, updatedAt: string | null, pinnedMessages: Array<{ __typename?: 'Message', reference: { __typename?: 'MessageReference', offerId: { __typename?: 'Offer', _id: any, title: string, price: { __typename?: 'OfferPrice', value: number, currency: { __typename?: 'Currency', code: string | null, symbol: string | null, _id: any } | null } | null, photo: Array<{ __typename?: 'OfferPhoto', bucket: string | null, key: string | null, location: string | null } | null> | null } | null, requestId: { __typename?: 'Request', _id: any } | null } | null } | null> | null, lastMessage: { __typename?: 'Message', content: string | null, createdAt: string | null, attachments: Array<{ __typename?: 'MessageAttachment', filename: string, type: Types.MessageAttachmentType, url: string } | null> | null, reference: { __typename?: 'MessageReference', offerId: { __typename?: 'Offer', title: string, photo: Array<{ __typename?: 'OfferPhoto', bucket: string | null, key: string | null, location: string | null } | null> | null } | null, orderId: { __typename?: 'Order', startAt: string | null, endAt: string | null } | null, requestId: { __typename?: 'Request', date: string | null, location: { __typename?: 'RequestLocation', description: string, place_id: string } | null } | null } | null } | null, participantsExcludingSelf: Array<{ __typename?: 'Account', _id: any, isOnline: boolean | null, lastOnlineDate: string | null, firstName: string | null, fullName: string | null, lastName: string | null, avatar: { __typename?: 'AccountAvatar', key: string | null } | null } | null> | null } | null };

export type GetChatStatusQueryVariables = Types.Exact<{
  input: Types.InputMaybe<Types.GetChatStatusInput>;
}>;


export type GetChatStatusQuery = { __typename?: 'Query', getChatStatus: { __typename?: 'GetChatPayload', chatId: string | null, isNew: boolean | null } | null };

export type ChatStatusMutationVariables = Types.Exact<{
  input: Types.InputMaybe<Types.ChatStatusInput>;
}>;


export type ChatStatusMutation = { __typename?: 'Mutation', chatStatus: { __typename?: 'DefaultPayload', status: string } | null };

export type ChatReadAllMutationVariables = Types.Exact<{
  input: Types.InputMaybe<Types.ChatReadAllInput>;
}>;


export type ChatReadAllMutation = { __typename?: 'Mutation', chatReadAll: { __typename?: 'DefaultPayload', status: string } | null };

export const ChatPreviewFragmentDoc = gql`
    fragment ChatPreview on Chat {
  _id
  lastMessage {
    content
    createdAt
    attachments {
      filename
      type
      url
    }
    reference {
      offerId {
        photo {
          bucket
          key
          location
        }
        title
      }
      orderId {
        startAt
        endAt
      }
      requestId {
        date
        location {
          description
          place_id
        }
      }
    }
  }
  participantsExcludingSelf {
    _id
    avatar {
      key
    }
    isOnline
    lastOnlineDate
    firstName
    fullName
    lastName
  }
  title
  unreadCount
  updatedAt
}
    `;
export const FindChatsDocument = gql`
    query FindChats($input: FindChatsInput) {
  findChats(input: $input) {
    hasNextPage
    items {
      ...ChatPreview
    }
    nextCreatedAt
    nextId
    total
  }
}
    ${ChatPreviewFragmentDoc}`;

/**
 * __useFindChatsQuery__
 *
 * To run a query within a React component, call `useFindChatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindChatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindChatsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindChatsQuery(baseOptions?: Apollo.QueryHookOptions<FindChatsQuery, FindChatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindChatsQuery, FindChatsQueryVariables>(FindChatsDocument, options);
      }
export function useFindChatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindChatsQuery, FindChatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindChatsQuery, FindChatsQueryVariables>(FindChatsDocument, options);
        }
export function useFindChatsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FindChatsQuery, FindChatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindChatsQuery, FindChatsQueryVariables>(FindChatsDocument, options);
        }
export type FindChatsQueryHookResult = ReturnType<typeof useFindChatsQuery>;
export type FindChatsLazyQueryHookResult = ReturnType<typeof useFindChatsLazyQuery>;
export type FindChatsSuspenseQueryHookResult = ReturnType<typeof useFindChatsSuspenseQuery>;
export type FindChatsQueryResult = Apollo.QueryResult<FindChatsQuery, FindChatsQueryVariables>;
export const GetChatDocument = gql`
    query GetChat($input: GetChatInput) {
  getChat(input: $input) {
    ...ChatPreview
    pinnedMessages {
      reference {
        offerId {
          _id
          price {
            ...OfferPrice
          }
          photo {
            bucket
            key
            location
          }
          title
        }
        requestId {
          _id
        }
      }
    }
  }
}
    ${ChatPreviewFragmentDoc}
${OfferPriceFragmentDoc}`;

/**
 * __useGetChatQuery__
 *
 * To run a query within a React component, call `useGetChatQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChatQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetChatQuery(baseOptions?: Apollo.QueryHookOptions<GetChatQuery, GetChatQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChatQuery, GetChatQueryVariables>(GetChatDocument, options);
      }
export function useGetChatLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChatQuery, GetChatQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChatQuery, GetChatQueryVariables>(GetChatDocument, options);
        }
export function useGetChatSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetChatQuery, GetChatQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetChatQuery, GetChatQueryVariables>(GetChatDocument, options);
        }
export type GetChatQueryHookResult = ReturnType<typeof useGetChatQuery>;
export type GetChatLazyQueryHookResult = ReturnType<typeof useGetChatLazyQuery>;
export type GetChatSuspenseQueryHookResult = ReturnType<typeof useGetChatSuspenseQuery>;
export type GetChatQueryResult = Apollo.QueryResult<GetChatQuery, GetChatQueryVariables>;
export const GetChatStatusDocument = gql`
    query GetChatStatus($input: GetChatStatusInput) {
  getChatStatus(input: $input) {
    chatId
    isNew
  }
}
    `;

/**
 * __useGetChatStatusQuery__
 *
 * To run a query within a React component, call `useGetChatStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChatStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChatStatusQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetChatStatusQuery(baseOptions?: Apollo.QueryHookOptions<GetChatStatusQuery, GetChatStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChatStatusQuery, GetChatStatusQueryVariables>(GetChatStatusDocument, options);
      }
export function useGetChatStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChatStatusQuery, GetChatStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChatStatusQuery, GetChatStatusQueryVariables>(GetChatStatusDocument, options);
        }
export function useGetChatStatusSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetChatStatusQuery, GetChatStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetChatStatusQuery, GetChatStatusQueryVariables>(GetChatStatusDocument, options);
        }
export type GetChatStatusQueryHookResult = ReturnType<typeof useGetChatStatusQuery>;
export type GetChatStatusLazyQueryHookResult = ReturnType<typeof useGetChatStatusLazyQuery>;
export type GetChatStatusSuspenseQueryHookResult = ReturnType<typeof useGetChatStatusSuspenseQuery>;
export type GetChatStatusQueryResult = Apollo.QueryResult<GetChatStatusQuery, GetChatStatusQueryVariables>;
export const ChatStatusDocument = gql`
    mutation ChatStatus($input: ChatStatusInput) {
  chatStatus(input: $input) {
    status
  }
}
    `;
export type ChatStatusMutationFn = Apollo.MutationFunction<ChatStatusMutation, ChatStatusMutationVariables>;

/**
 * __useChatStatusMutation__
 *
 * To run a mutation, you first call `useChatStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChatStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [chatStatusMutation, { data, loading, error }] = useChatStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChatStatusMutation(baseOptions?: Apollo.MutationHookOptions<ChatStatusMutation, ChatStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChatStatusMutation, ChatStatusMutationVariables>(ChatStatusDocument, options);
      }
export type ChatStatusMutationHookResult = ReturnType<typeof useChatStatusMutation>;
export type ChatStatusMutationResult = Apollo.MutationResult<ChatStatusMutation>;
export type ChatStatusMutationOptions = Apollo.BaseMutationOptions<ChatStatusMutation, ChatStatusMutationVariables>;
export const ChatReadAllDocument = gql`
    mutation ChatReadAll($input: ChatReadAllInput) {
  chatReadAll(input: $input) {
    status
  }
}
    `;
export type ChatReadAllMutationFn = Apollo.MutationFunction<ChatReadAllMutation, ChatReadAllMutationVariables>;

/**
 * __useChatReadAllMutation__
 *
 * To run a mutation, you first call `useChatReadAllMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChatReadAllMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [chatReadAllMutation, { data, loading, error }] = useChatReadAllMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChatReadAllMutation(baseOptions?: Apollo.MutationHookOptions<ChatReadAllMutation, ChatReadAllMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChatReadAllMutation, ChatReadAllMutationVariables>(ChatReadAllDocument, options);
      }
export type ChatReadAllMutationHookResult = ReturnType<typeof useChatReadAllMutation>;
export type ChatReadAllMutationResult = Apollo.MutationResult<ChatReadAllMutation>;
export type ChatReadAllMutationOptions = Apollo.BaseMutationOptions<ChatReadAllMutation, ChatReadAllMutationVariables>;